export const THEME = {
	colors: {
		primary: '#188af6',
		secondary: '#0070f3',
	},
}

export const GRADIENT_STYLES = {
	'background': 'linear-gradient(to right, #45B649, #DCE35B)',
	'-webkit-background-clip': 'text',
	'-webkit-text-fill-color': 'transparent',
}

export const BORDER_STYLES = {
	borderWidth: 1,
	borderColor: 'divider',
	borderStyle: 'solid',
}